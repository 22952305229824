<template>
  <div>
   
    <div class="topWrap">
          <div class="content">
               <h3>注册</h3>
               <el-form :model="form" :rules="rules" ref="ruleForm" :show-message="false">
                <el-form-item prop="name">
                  <el-input v-model="form.nickname" placeholder="昵称">
                    <i  slot="prefix" class="iconfont"> &#xe64d; </i>
                  </el-input>
                </el-form-item>
                 <el-form-item prop="mobileOrEmail">
                  <el-input v-model="form.mobileOrEmail" placeholder="请输入手机号码">
                     <i  slot="prefix" class="iconfont"> &#xe641; </i>
                  </el-input>
                </el-form-item>
                 <el-form-item prop="verifyCode">
                  <el-input v-model="form.verifyCode" placeholder="请输入短信验证码">
                    <i  slot="prefix" class="iconfont"> &#xe647; </i>
                    <em slot="suffix" class="yzm" @click="sendVerifyCode"> {{sendBtnText}}  </em>
                  </el-input>
                </el-form-item>
                  <el-form-item prop="pwd">
                  <el-input v-model="form.loginPwd" type="password" placeholder="设置6至20位登录密码">
                     <i  slot="prefix" class="iconfont"> &#xe65d; </i>
                  </el-input>
                </el-form-item>
                  <el-form-item prop="repwd">
                  <el-input v-model="form.reLoginPwd" type="password" placeholder="请再次输入登录密码">
                  <i  slot="prefix" class="iconfont"> &#xe65d; </i>
                  </el-input>
                </el-form-item>
                 <el-form-item prop="allow">
                     <el-checkbox v-model="form.allow">我同意</el-checkbox>
                      <router-link :to="{path:'/Agreement',query: {}}" class="allow_a"> 
                          《用户服务协议》
                      </router-link>
                 </el-form-item>
                   <el-form-item>
                    <el-button type="primary" @click="onSubmit">注册</el-button>
                  </el-form-item>
              </el-form>  
               
                  <div class="goLogin">
                     <router-link :to="{path:'/Login',query: {}}"> 
                            已有账号，去登录
                       </router-link>
                  </div> 
                
          </div>
    </div>

  </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'
import {register} from '@/api/UserService'
import {sendSmsVerifyCode} from '@/api/SmsService'
export default {
  name: 'Rigist',
  components: {
    slider,
    slideritem
  },
  mounted () {
    this.init()
  },
     data () {
      return {
        sendBtnText:'发送验证码',
        sendStatus:-1,
        form:{
          nickname:'',
          mobileOrEmail:'',
          verifyCode:'',
          loginPwd:'',
          reLoginPwd:'',
          verifyCodeId:null,
          allow:false
        },
         rules: {
          name: [
            // { required: true, message: '请输入昵称', trigger: 'blur' }
          ],
         }
      }
    },

  methods: {
    init () {
    },
    sendVerifyCode(){
      if(this.sendStatus!=-1){
        return false;
      }

      this.sendStatus=0;
      this.sendBtnText='发送中';

      sendSmsVerifyCode(`+86-${this.form.mobileOrEmail}`,'REGISTER').then(({data,res})=>{
          this.form.verifyCodeId=data;
          this.sendStatus=1;

          let that=this;
          let count=90;
          let refreshVerifyCode=()=>{
              if(count==0){
                 that.sendBtnText='重新发送';
                 that.sendStatus=-1;
                 return false;
              }else{
                 count--;
                 that.sendBtnText=`${count}秒后重发`;
                 setTimeout(refreshVerifyCode,1000);
              }
          }
          setTimeout(refreshVerifyCode,1000);
      }).catch(e=>{
        this.sendBtnText='发送验证码';
        this.sendStatus=-1;
        console.log(e);
      });
    },
    onSubmit(){
      register(this.form).then((data)=>{
         this.$router.push({path: '/Login'});
      }).catch(e=>{
          console.log(e);
      });
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../../assets/css/loginRigist.scss";
  .topWrap .content .goLogin{
    justify-content:flex-end;
  }
  .allow_a{color: #007DFF;}
</style>